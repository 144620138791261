import React, { useEffect, useState } from 'react';
import { unstable_batchedUpdates } from 'react-dom';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft, faArrowRight } from '@fortawesome/free-solid-svg-icons';
import useMounted from 'utils/hooks/useMounted';
import {
  requestAssignedFeatureList,
  requestDeleteGroup,
  requestGroupInfo,
  requestPutGroupFeature,
  requestPutGroupInfo,
  requestUnassignedFeatureList,
} from 'utils/http/api/feature-groups';
import { GroupInfoPutRequest } from 'utils/http/api/feature-groups/request';
import { GroupVO } from 'models/vo/GroupVo';
import { FeatureVO } from 'models/vo/FeatureVO';
import { HeaderTitle } from 'components/stateless/Title/HeaderTitle';
import { SectionTitle } from 'components/stateless/Title/SectionTitle';
import Button, { ButtonSizeEnum, ButtonVariantEnum, ButtonColorEnum } from 'components/stateless/Button/Button';
import '../../../scss/admin/Detail.scss';
import DetailGroupTable from './DetailGroupTable';

const DetailGroup = () => {
  const { groupId } = useParams();
  const { t } = useTranslation();
  const mounted = useMounted();
  const navigate = useNavigate();
  const [groupInfo, setGroupInfo] = useState<GroupVO>();
  const { register, handleSubmit, setValue } = useForm<GroupInfoPutRequest>({
    mode: 'onSubmit',
    shouldFocusError: true,
    defaultValues: { featureGroupId: Number(groupId) },
  });
  const [unassignedFeatureList, setUnassignedFeatureList] = useState<FeatureVO[]>([]);
  const [assignedFeatureList, setAssignedFeatureList] = useState<FeatureVO[]>([]);
  const [originalUnassignedFeatureList, setOriginalUnassignedFeatureList] = useState<FeatureVO[]>([]);
  const [originalAssignedFeatureList, setOriginalAssignedFeatureList] = useState<FeatureVO[]>([]);
  const [middleUnassignedFeatureList, setMiddleUnassignedFeatureList] = useState<FeatureVO[]>([]);
  const [middleAssignedFeatureList, setMiddleAssignedFeatureList] = useState<FeatureVO[]>([]);
  const [isFeatureEdit, setIsFeatureEdit] = useState<boolean>(false);
  const [isGroupEdit, setIsGroupEdit] = useState<boolean>(false);

  useEffect(() => {
    if (mounted) {
      fetchAll(groupId as string, 1, 1000);
    }
  }, [mounted]);

  useEffect(() => {
    setValue('featureGroupName', groupInfo?.featureGroupName as string);
  }, [groupInfo]);

  async function fetchAll(id: string, pageNumber: number, sizePerPage: number) {
    try {
      const responseGroupInfoData = await requestGroupInfo(Number(id));
      const responseAssignedFeatureList = await requestAssignedFeatureList(Number(id), pageNumber, sizePerPage);
      const responseUnassignedFeatureList = await requestUnassignedFeatureList(Number(id), pageNumber, sizePerPage);
      unstable_batchedUpdates(() => {
        setGroupInfo(responseGroupInfoData);
        setAssignedFeatureList(responseAssignedFeatureList);
        setUnassignedFeatureList(responseUnassignedFeatureList);
        setOriginalAssignedFeatureList(responseAssignedFeatureList);
        setOriginalUnassignedFeatureList(responseUnassignedFeatureList);
      });
    } catch (e) {
      console.log('error', e);
    }
  }

  const setUnassignedMiddleList = (id: number) => {
    if (isFeatureEdit) {
      const featureInfo = unassignedFeatureList.find((feature) => feature.id === id) as FeatureVO;
      const isIncludeMiddle = middleUnassignedFeatureList.find((feature) => feature.id === id) as FeatureVO;
      if (middleAssignedFeatureList.length !== 0) {
        unstable_batchedUpdates(() => {
          setMiddleAssignedFeatureList([]);
          setMiddleUnassignedFeatureList([...middleUnassignedFeatureList, featureInfo]);
        });
      } else if (isIncludeMiddle) {
        setMiddleUnassignedFeatureList(middleUnassignedFeatureList.filter((feature) => feature.id !== id));
      } else {
        setMiddleUnassignedFeatureList([...middleUnassignedFeatureList, featureInfo]);
      }
    }
  };
  const setAssignedMiddleList = (id: number) => {
    if (isFeatureEdit) {
      const featureInfo = assignedFeatureList.find((feature) => feature.id === id) as FeatureVO;
      const isIncludeMiddle = middleAssignedFeatureList.find((feature) => feature.id === id) as FeatureVO;
      if (middleUnassignedFeatureList.length !== 0) {
        unstable_batchedUpdates(() => {
          setMiddleUnassignedFeatureList([]);
          setMiddleAssignedFeatureList([...middleAssignedFeatureList, featureInfo]);
        });
      } else if (isIncludeMiddle) {
        setMiddleAssignedFeatureList(middleAssignedFeatureList.filter((feature) => feature.id !== id));
      } else {
        setMiddleAssignedFeatureList([...middleAssignedFeatureList, featureInfo]);
      }
    }
  };

  const isSelected = (featureId: number) => {
    const isIncludeAssignedMiddle = middleAssignedFeatureList.find((feature) => feature.id === featureId) as FeatureVO;
    const isIncludeUnassignedMiddle = middleUnassignedFeatureList.find(
      (feature) => feature.id === featureId,
    ) as FeatureVO;
    if (isIncludeAssignedMiddle || isIncludeUnassignedMiddle) return true;
    else return false;
  };
  const renderNoDataAvailable = () => {
    return <li className="border-none">{t('text:사용가능한_데이터가_없습니다')}</li>;
  };

  const renderResultFeatureList = (featureList: FeatureVO[], setMiddleList: Function) => {
    return featureList.length !== 0
      ? featureList.map((feature) => {
          return (
            <li
              className={isSelected(feature.id) ? 'on' : ''}
              key={feature.id}
              onClick={() => setMiddleList(feature.id)}
            >
              {feature.name}
            </li>
          );
        })
      : renderNoDataAvailable();
  };
  const moveToAssigned = () => {
    if (middleAssignedFeatureList.length === 0) {
      unstable_batchedUpdates(() => {
        setAssignedFeatureList([...middleUnassignedFeatureList, ...assignedFeatureList]);
        setUnassignedFeatureList([
          ...unassignedFeatureList.filter((feature) => {
            for (let i = 0; i < middleUnassignedFeatureList.length; i++) {
              if (middleUnassignedFeatureList[i].id === feature.id) return false;
            }
            return true;
          }),
        ]);
        setMiddleAssignedFeatureList([...middleUnassignedFeatureList]);
        setMiddleUnassignedFeatureList([]);
      });
    }
  };
  const moveToUnassigned = () => {
    if (middleUnassignedFeatureList.length === 0) {
      unstable_batchedUpdates(() => {
        setUnassignedFeatureList([...middleAssignedFeatureList, ...unassignedFeatureList]);
        setAssignedFeatureList([
          ...assignedFeatureList.filter((feature) => {
            for (let i = 0; i < middleAssignedFeatureList.length; i++) {
              if (middleAssignedFeatureList[i].id === feature.id) return false;
            }
            return true;
          }),
        ]);

        setMiddleUnassignedFeatureList([...middleAssignedFeatureList]);
        setMiddleAssignedFeatureList([]);
      });
    }
  };

  const deleteGroup = async () => {
    if (confirm(t('text:해당_그룹을_삭제하시겠습니까?'))) {
      try {
        await requestDeleteGroup(Number(groupId));
        alert(t('text:그룹이_삭제되었습니다'));
        navigate('/admin/group/group-list');
      } catch (e) {
        console.log('error', e);
      }
    } else {
      return false;
    }
  };

  const goBack = () => {
    navigate(-1);
  };
  const onClickEditFeatureCancel = () => {
    if (confirm(t('text:수정을_취소하시겠습니까?'))) {
      unstable_batchedUpdates(() => {
        setIsFeatureEdit(false);
        setUnassignedFeatureList([...originalUnassignedFeatureList]);
        setAssignedFeatureList([...originalAssignedFeatureList]);
        setMiddleAssignedFeatureList([]);
        setMiddleUnassignedFeatureList([]);
      });
    } else {
      return false;
    }
  };

  const onClickEditGroupCancel = () => {
    if (confirm(t('text:수정을_취소하시겠습니까?'))) {
      unstable_batchedUpdates(() => {
        setIsGroupEdit(false);
        setValue('featureGroupName', groupInfo?.featureGroupName as string);
      });
    } else return false;
  };

  const putGroupInfo = async (data: GroupInfoPutRequest) => {
    if (confirm(t('text:수정하시겠습니까?'))) {
      if (data.featureGroupName === groupInfo?.featureGroupName) {
        alert('변경사항이_없습니다');
      } else {
        try {
          const response = await requestPutGroupInfo(data);
          alert(t('text:수정되었습니다'));
          unstable_batchedUpdates(() => {
            setGroupInfo({ ...response });
            setIsGroupEdit(false);
          });
        } catch (e) {
          alert(t('text:역할명이_중복되었습니다'));
        }
      }
    } else return false;
  };

  const postFeatureList = async () => {
    const featureIdList = assignedFeatureList.map((feature) => feature.id) as number[];
    if (confirm(t('text:수정하시겠습니까?'))) {
      if (middleAssignedFeatureList.length === 0 && middleUnassignedFeatureList.length === 0) {
        alert(t('text:변경사항이_없습니다'));
      } else {
        try {
          await requestPutGroupFeature({ featureGroupId: Number(groupId), featureIdList });
          alert(t('text:수정되었습니다'));
          setIsFeatureEdit(false);
          setOriginalAssignedFeatureList([...assignedFeatureList]);
          setOriginalUnassignedFeatureList([...unassignedFeatureList]);
          setMiddleAssignedFeatureList([]);
          setMiddleUnassignedFeatureList([]);
        } catch (e) {
          console.log('error', e);
        }
      }
    } else return false;
  };

  const errorHandler = (o: any) => {
    if (o.name.type === 'required') {
      alert(t('text:역할명을_입력해주세요'));
    }
  };

  return (
    <div className="content-wrap">
      <HeaderTitle title={t('text:역할_그룹_상세')}>
        <Button variant={ButtonVariantEnum.OUTLINED} size={ButtonSizeEnum.SM} onClick={goBack} className="go-back-btn">
          <FontAwesomeIcon icon={faArrowLeft} />
        </Button>
      </HeaderTitle>
      <div className="content-area">
        <SectionTitle title={t('text:역할_그룹_정보')} className={'section-title-btn'}>
          {!isGroupEdit ? (
            <div className="edit-btn-wrap">
              <Button variant={ButtonVariantEnum.OUTLINED} size={ButtonSizeEnum.SM} onClick={deleteGroup}>
                {t('text:삭제')}
              </Button>
              <Button
                variant={ButtonVariantEnum.OUTLINED}
                size={ButtonSizeEnum.SM}
                onClick={() => setIsGroupEdit(true)}
              >
                {t('text:수정')}
              </Button>
            </div>
          ) : (
            <div className="edit-btn-wrap">
              <Button
                variant={ButtonVariantEnum.OUTLINED}
                size={ButtonSizeEnum.SM}
                onClick={onClickEditGroupCancel}
                color={ButtonColorEnum.SECONDARY}
              >
                {t('text:취소')}
              </Button>
              <Button
                variant={ButtonVariantEnum.OUTLINED}
                size={ButtonSizeEnum.SM}
                onClick={handleSubmit(putGroupInfo, errorHandler)}
                style={{ width: '60px' }}
              >
                {t('text:저장')}
              </Button>
            </div>
          )}
        </SectionTitle>
        <div className="info-field group">
          <div className="row">
            <div className="row-content">
              <div className="label">{t('text:역할명')}</div>
              {!isGroupEdit ? (
                <div className="uneditable-input">
                  {groupInfo?.featureGroupName ? groupInfo?.featureGroupName : '-'}
                </div>
              ) : (
                <div className="editable-input">
                  <input {...register('featureGroupName', { required: true, max: 20 })} />
                </div>
              )}
            </div>
            <div className="row-content">
              <div className="label">{t('text:등록일시')}</div>
              <div className="uneditable-input">
                {groupInfo?.createDateTime
                  ? t('format:datetime', { value: groupInfo?.createDateTime, key: 'date' })
                  : '-'}
              </div>
            </div>
          </div>
        </div>
        <div className="content-field">
          <div className="content-setting">
            <SectionTitle title={t('text:할당되지_않은_기능')} />
            <div className="top-list">
              <ul>
                <li className="list-title">{t('text:기능명')}</li>
                {renderResultFeatureList(unassignedFeatureList, setUnassignedMiddleList)}
              </ul>
            </div>
          </div>
          <div className="button-wrap">
            <Button
              variant={ButtonVariantEnum.OUTLINED}
              size={ButtonSizeEnum.SM}
              onClick={moveToUnassigned}
              disabled={!isFeatureEdit || middleAssignedFeatureList.length === 0}
            >
              <FontAwesomeIcon icon={faArrowLeft} />
            </Button>
            <Button
              variant={ButtonVariantEnum.OUTLINED}
              size={ButtonSizeEnum.SM}
              onClick={moveToAssigned}
              disabled={!isFeatureEdit || middleUnassignedFeatureList.length === 0}
            >
              <FontAwesomeIcon icon={faArrowRight} />
            </Button>
          </div>
          <div className="content-setting">
            <SectionTitle title={t('text:할당된_기능')} className={'section-title-btn'}>
              {!isFeatureEdit ? (
                <div className="edit-btn-wrap">
                  <Button
                    variant={ButtonVariantEnum.OUTLINED}
                    size={ButtonSizeEnum.SM}
                    onClick={() => setIsFeatureEdit(true)}
                  >
                    {t('text:수정')}
                  </Button>
                </div>
              ) : (
                <div className="edit-btn-wrap">
                  <Button
                    variant={ButtonVariantEnum.OUTLINED}
                    size={ButtonSizeEnum.SM}
                    onClick={onClickEditFeatureCancel}
                    color={ButtonColorEnum.SECONDARY}
                  >
                    {t('text:취소')}
                  </Button>
                  <Button
                    variant={ButtonVariantEnum.OUTLINED}
                    size={ButtonSizeEnum.SM}
                    onClick={postFeatureList}
                    style={{ width: '60px' }}
                  >
                    {t('text:저장')}
                  </Button>
                </div>
              )}
            </SectionTitle>
            <div className="top-list">
              <ul>
                <li className="list-title">{t('text:기능명')}</li>
                {renderResultFeatureList(assignedFeatureList, setAssignedMiddleList)}
              </ul>
            </div>
          </div>
        </div>
        <div className="content-table">
          <DetailGroupTable id={groupId as string} />
        </div>
      </div>
    </div>
  );
};

export default DetailGroup;
