import { isNil } from 'lodash-es';
import i18n from 'i18next';
import Big from 'big.js';
import { languageType } from '../i18n';
import { numberFormatterEN } from './en';

const REG_NUMBER = /^[-]?\d+(\.?\d*)?$/;

export const localeNumberFormatter = (value: number | string | undefined) => {
  if (isNil(value)) return '-';

  if (typeof value === 'number') {
    if (isNaN(value)) return '-';
    else value = value?.toString();
  }

  if (!REG_NUMBER.test(value?.toString())) return '-';

  const dotIdx = value.indexOf('.');

  value = dotIdx === -1 ? value : Big(value)?.toFixed(4, 0).replace(/0+$/, '');
  if (value.slice(-1) === '.') value = value.slice(0, -1);

  let returnValue = '';

  switch (i18n.language) {
    case languageType.EN_USA:
    case languageType.KO:
      returnValue = numberFormatterEN(value);
      break;
    default:
      returnValue = value;
  }

  return returnValue;
};
