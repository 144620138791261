import React, { CSSProperties } from 'react';
import './Button.scss';

export enum ButtonColorEnum {
  PRIMARY = 'primary',
  SECONDARY = 'secondary',
  RED = 'red',
  BLUE = 'blue',
}

export enum ButtonVariantEnum {
  FILLED = 'filled',
  OUTLINED = 'outlined',
}

export enum ButtonSizeEnum {
  DEFAULT = 'df',
  SM = 'sm',
  MD = 'md',
  LG = 'lg',
}

interface PropsType {
  className?: string;
  children: JSX.Element | (JSX.Element | null | undefined)[] | string | null | undefined;
  variant?: ButtonVariantEnum;
  size: ButtonSizeEnum;
  color?: ButtonColorEnum;
  style?: CSSProperties | undefined;
  disabled?: boolean;
  type?: 'reset' | 'button' | 'submit' | undefined;
  onClick: (e?: any) => void;
}

const Button = ({
  className = '',
  children,
  variant = ButtonVariantEnum.FILLED,
  size,
  color = ButtonColorEnum.PRIMARY,
  disabled,
  onClick,
  style,
  type = 'button',
}: PropsType) => {
  const handleClick = (e?: any) => {
    onClick(e);
  };
  return (
    <button
      type={type}
      disabled={disabled}
      className={`common-button ${variant} ${color} ${size} ${className}`}
      onClick={handleClick}
      style={style}
    >
      {children}
    </button>
  );
};

export default Button;
