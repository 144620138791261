import React, { useCallback, useEffect, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { getSignIn } from 'utils/storage/LocalStorage';
import './LeftMenu.scss';

export interface leftMenuInfoType {
  mainMenu: { title: string; path?: string; hidden?: boolean };
  subMenu?: { title: string; path: string; hidden?: boolean }[];
}

interface LeftMenuProps {
  menuInfo: Array<leftMenuInfoType>;
}

const LeftMenu = ({ menuInfo }: LeftMenuProps) => {
  const currentUrl = useLocation().pathname;
  const { t } = useTranslation();
  const signInfo = getSignIn();
  const isSameSplitCurrentPathAndTargetPath = useCallback(
    (targetMenuPath: string, nthPath: number) => {
      return currentUrl.split('/')[nthPath] == targetMenuPath.split('/')[nthPath];
    },
    [currentUrl],
  );

  const renderAccordionMenu = () => {
    return menuInfo?.map((item, idx) => {
      return (
        <div
          key={idx}
          className={`gnb__main-menu ${
            item.mainMenu.path && isSameSplitCurrentPathAndTargetPath(item.mainMenu.path, 2) ? 'on' : ''
          }`}
        >
          <Link to={item.mainMenu.path ? item.mainMenu.path : '#'}>{item.mainMenu.title}</Link>
        </div>
      );
    });
  };

  return (
    <div className="left-menu">
      <div className="profile">
        <div className="profile__item">
          <div className="profile__flex">
            <p className="profile__info">{t('text:관리자')}</p>
            <span className="profile__role-badge">{signInfo?.email}</span>
          </div>
        </div>
      </div>
      <div className="gnb">{renderAccordionMenu()}</div>
    </div>
  );
};

export default LeftMenu;
