import React from 'react';
import { faQuestionCircle, faSort } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { BackGroundType } from 'enums';
export interface HeaderTypes {
  headerText?: string | JSX.Element;
  colWidths?: number;
  className?: string;
  hasStatusDescription?: boolean;
  showStatusDescriptionFunc?: Function;
  hasCheckBox?: boolean;
  handleClickAllCheckBox?: Function;
  checkBoxId?: string;
  allCheekdPages?: number[];
  hasSort?: boolean;
  sortFunc?: Function;
}

export interface bigHeaderTypes {
  headerText: string;
  colSpan: number;
  rowSpan?: number;
  className?: string;
}

interface TableHeaderProps {
  header: HeaderTypes[];
  bigHeader?: bigHeaderTypes[];
  backGroundType?: BackGroundType;
}

const TableHeader = ({ header, backGroundType = BackGroundType.Gray, bigHeader }: TableHeaderProps) => {
  return (
    <>
      <colgroup>
        {header.map((value, index) => (
          <col key={index} style={{ width: `${value.colWidths}px` }} />
        ))}
      </colgroup>
      <thead>
        {bigHeader && (
          <tr>
            {bigHeader.map((bigHeaderItems, index) => (
              <th
                scope="col"
                key={index}
                colSpan={bigHeaderItems.colSpan}
                rowSpan={bigHeaderItems?.rowSpan}
                className={bigHeaderItems.className ? bigHeaderItems.className : ''}
              >
                {bigHeaderItems.headerText}
              </th>
            ))}
          </tr>
        )}
        <tr>
          {header.map((value, index) => (
            <th scope="col" key={index} className={`${value.className ? value.className : backGroundType}`}>
              {value.headerText}
              {value.hasStatusDescription && (
                <a
                  href="#"
                  className="status-question-button"
                  onClick={(e) => {
                    e.preventDefault();

                    value.showStatusDescriptionFunc && value.showStatusDescriptionFunc();
                  }}
                >
                  <FontAwesomeIcon icon={faQuestionCircle} />
                </a>
              )}
              {value.hasCheckBox && (
                <div className="text-center">
                  <input
                    id={value?.checkBoxId}
                    className="form-check-input m-0"
                    type="checkbox"
                    onChange={(e) => {
                      value.handleClickAllCheckBox !== undefined && value.handleClickAllCheckBox(e);
                    }}
                    checked={value?.allCheekdPages?.includes(Number(value?.checkBoxId))}
                  />
                </div>
              )}
              {value.hasSort && (
                <a
                  href="#"
                  className="sort-button"
                  onClick={(e) => {
                    e.preventDefault();

                    value.sortFunc && value.sortFunc();
                  }}
                >
                  <FontAwesomeIcon icon={faSort} />
                </a>
              )}
            </th>
          ))}
        </tr>
      </thead>
    </>
  );
};

export default TableHeader;
