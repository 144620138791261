import React from 'react';
import './Title.scss';

interface PropsType {
  children?: JSX.Element | (JSX.Element | null | undefined | boolean)[] | null | undefined | boolean;
  title?: string;
  className?: string;
}

export const SectionTitle = ({ children, title = '', className }: PropsType) => {
  return (
    <div className={className ? `section-title ${className}` : 'section-title'}>
      <h2 className="me-auto">{title}</h2>
      {children}
    </div>
  );
};
