import React from 'react';
import { useTranslation } from 'react-i18next';
import isEmpty from 'lodash-es/isEmpty';
interface PropsType {
  numOfCol?: number;
  children?: JSX.Element | (JSX.Element | null | undefined | boolean)[] | null | undefined | boolean;
  id?: string;
}
function TableBody({ children, numOfCol, id }: PropsType) {
  const { t } = useTranslation();

  const renderNoDataAvailable = () => {
    return (
      <tr>
        <td colSpan={numOfCol} className="text-center">
          {t('text:사용가능한_데이터가_없습니다')}
        </td>
      </tr>
    );
  };
  return (
    <tbody id={id} className="bg-white">
      {isEmpty(children) ? renderNoDataAvailable() : children}
    </tbody>
  );
}

export default TableBody;
