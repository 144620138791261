import i18n, { TFunctionResult } from 'i18next';
import dayjs from 'dayjs';

export enum FormatEnum {
  NUMBER = 'number',
  DATE = 'date',
  DATETIME = 'datetime',
  PERCENT = 'percent',
  CODE = 'code',
}

export const dateFormatting = (originalValue: any, format: FormatEnum.DATE | FormatEnum.DATETIME): string => {
  return dayjs(originalValue).isValid()
    ? i18n.t(`format:${format}`, {
        value: originalValue,
        key: 'date',
      })
    : '-';
};

export const numberFormatting = (originalValue: any) => {
  return i18n.t('format:number', { value: originalValue });
};

export const percentFormatting = (originalValue: any, hasPercentSign: boolean = true) => {
  return `${i18n.t('format:percent', { value: originalValue })} ${hasPercentSign ? ' %' : ''}`;
};

export const isNull = (value: any): boolean => {
  if (value === undefined || value === null || value === '' || value === 'undefined' || value === 'null') {
    return true;
  } else {
    return false;
  }
};

export const valueManager = (
  originalValue: string | undefined | null | number | TFunctionResult | boolean,
  format?: FormatEnum,
): string | undefined | null | number | TFunctionResult | boolean => {
  if (isNull(originalValue)) return '-';
  else {
    switch (format) {
      case FormatEnum.DATE:
      case FormatEnum.DATETIME:
        return dateFormatting(originalValue, format);
      case FormatEnum.NUMBER:
        return numberFormatting(originalValue);
      case FormatEnum.PERCENT:
        return percentFormatting(originalValue);
      case FormatEnum.CODE:
        if (typeof originalValue === 'string') {
          const status = originalValue?.split('.');
          return status[1] == 'undefined' || status[1] == 'null' ? '-' : originalValue;
        } else {
          return originalValue;
        }
      default:
        return originalValue;
    }
  }
};
