import React from 'react';
import { valueManager } from 'utils/valueManager/valueManager';
import { useTranslation } from 'react-i18next';
import { TFunctionResult } from 'i18next';

type FormatType = 'number' | 'date' | 'datetime' | 'percent' | 'code';

interface PropsType {
  data?: string | number | TFunctionResult | null;
  className?: string;
  format?: FormatType;
  children?: JSX.Element | (JSX.Element | null | undefined | boolean)[] | null | undefined | boolean;
}

const Td = ({ className, data, children, format = undefined }: PropsType) => {
  const { t } = useTranslation(['format']);

  const formatData = (value: any, format?: FormatType) => {
    switch (format) {
      case 'date':
        return t('format:date', {
          value: value,
          key: 'date',
        });
      case 'datetime':
        return t('format:datetime', {
          value: value,
          key: 'date',
        });
      case 'number':
        if (typeof value === 'number' || typeof value === 'string') return t('format:number', { value: value });
        else return value;
      case 'percent':
        if (typeof value === 'number') {
          return `${t('format:number', { value: value })}%`;
        } else {
          return value;
        }
      case 'code':
        const status = value.split('.');
        return status[1] == 'undefined' || status[1] == 'null' ? '-' : value;
      default:
        return valueManager(data);
    }
  };

  return (
    <>
      {children ? (
        <td className={className}>{children}</td>
      ) : (
        <td className={format === 'number' || format === 'percent' ? 'text-end' : className}>
          {data?.toString() ? formatData(data, format) : valueManager(data)}
          {children}
        </td>
      )}
    </>
  );
};

export default Td;
