import React from 'react';
import './Title.scss';

interface PropsType {
  children?: JSX.Element | (JSX.Element | null | undefined)[] | null | undefined;
  title?: string;
}

export const HeaderTitle = ({ children, title = '' }: PropsType) => {
  return (
    <div className="header-title">
      {children}
      <h1>{title}</h1>
    </div>
  );
};
