import React from 'react';
import { Route, Routes } from 'react-router-dom';
import LoginPage from 'pages/login/LoginPage';
import AdminRouter from 'router/adminRouter/AdminRouter';
import RedirectToLoginOrHome from './guard/RedirectToLoginOrHome';

const Root: React.FC = () => (
  <Routes>
    <Route element={<RedirectToLoginOrHome />}>
      <Route path="/" element={<LoginPage />} />
    </Route>
    <Route path="/admin/*" element={<AdminRouter />} />
  </Routes>
);
export default Root;
