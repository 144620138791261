import React from 'react';
import { faChevronLeft, faChevronRight, faSortDown } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useTranslation } from 'react-i18next';
import './Pagination.scss';

interface PaginationProps {
  pageable: {
    currentPage: number;
    totalRows: number;
    sizePerPage: number;
    paginateProxy(page: number, sizePerPage: number, cb: Function): void;
  }; // 페이지당 몇개 보여줄지
  paginate(page: number, sizePerPage: number): void; // 부모로 페이지 번호 보내주는 함수
  showSelectRow?: boolean;
}

interface PaginationListType {
  id: string;
  ellipsis: boolean;
  pageNum: number | null;
}

const Pagination = ({ showSelectRow = true, ...paginationProps }: PaginationProps) => {
  let pagination: PaginationListType[] = [];

  const currentPage = paginationProps.pageable.currentPage;
  const totalRows = paginationProps.pageable.totalRows;
  const perPage = paginationProps.pageable.sizePerPage;
  const paginateProxy = paginationProps.pageable.paginateProxy;
  const paginate = paginationProps.paginate;

  const { t } = useTranslation();

  const pages = Math.ceil(totalRows / perPage); // 페이지 개수

  const makeList = (list: Array<number | null>) => {
    const pagination = [];

    for (const key in list) {
      // 생략하는것을 0으로 넣는다
      if (list[key] == null) {
        pagination.push({ id: key, ellipsis: true, pageNum: null });
      } else {
        pagination.push({ id: key, ellipsis: false, pageNum: list[key] });
      }
    }
    return pagination;
  };

  // 페이지 개수가 7 이하일때
  if (pages <= 7) {
    const list = [];
    for (let i = 1; i <= pages; i++) {
      list.push(i);
      pagination = makeList(list);
    }
  }

  // 8일때
  else if (pages === 8) {
    // 시작 ~ 4
    if (currentPage <= 4) {
      pagination = makeList([1, 2, 3, 4, 5, null, pages]);
    }
    // 현재 페이지가 5 이하
    else {
      pagination = makeList([1, null, 4, 5, 6, 7, pages]);
    }
  }

  // 9 이상일 때
  else {
    // // 시작 ~ 4
    if (currentPage <= 4) {
      pagination = makeList([1, 2, 3, 4, 5, null, pages]);
    }

    // last-4 ~ last
    else if (currentPage >= pages - 4) {
      pagination = makeList([1, null, pages - 4, pages - 3, pages - 2, pages - 1, pages]);
    }
    // 나머지 ..
    else {
      pagination = makeList([1, null, currentPage - 1, currentPage, currentPage + 1, null, pages]);
    }
  }

  const changePage = (page: number, e: any) => {
    e.preventDefault();

    if (page !== currentPage) {
      paginateProxy(page, perPage, paginate); // 서버로 요청을 위한 페이지 넘버 설정
    }
  };

  const goToPrevPage = (e: any) => {
    e.preventDefault();

    if (currentPage - 1 === 0) {
      paginateProxy(currentPage, perPage, paginate);
    } else {
      paginateProxy(currentPage - 1, perPage, paginate);
    }
  };
  const goToNextPage = (e: any) => {
    e.preventDefault();

    if (currentPage === totalRows) {
      paginateProxy(currentPage, perPage, paginate);
    } else {
      paginateProxy(currentPage + 1, perPage, paginate);
    }
  };

  // select option
  const changeSelectValue = (e: any) => {
    paginateProxy(1, e.target.value, paginate);
  };

  const handleClick = (e: any) => {
    // a href 클릭 이벤트 방지
    e.preventDefault();
  };

  return (
    <div className="pagination">
      <div className="pagination__select">
        {showSelectRow && (
          <>
            <select value={perPage} onChange={changeSelectValue}>
              <option value={1}>1 {t('text:rows')}</option>
              <option value={2}>2 {t('text:rows')}</option>
              <option value={5}>5 {t('text:rows')}</option>
              <option value={10}>10 {t('text:rows')}</option>
            </select>
            <FontAwesomeIcon icon={faSortDown} />
          </>
        )}
      </div>
      <div className="pagination__data">
        <div className="pagination__data__summary">
          {totalRows == 0 ? (
            <span>0 ~ 0</span>
          ) : (
            <span>
              {currentPage * perPage - perPage + 1} ~ {currentPage === pages ? totalRows : currentPage * perPage}
            </span>
          )}{' '}
          {t('text:of')} <span>{totalRows}</span>
        </div>
        <button
          className="pagination__data__arrow-btn"
          onClick={goToPrevPage}
          disabled={currentPage == 1 ? true : false}
        >
          <span>
            <FontAwesomeIcon icon={faChevronLeft} />
          </span>
        </button>
        <div className="pagination__data__num-btn">
          <ol className="flex">
            {pagination.map((page) => {
              if (!page.ellipsis) {
                return (
                  <li
                    className={currentPage === page.pageNum ? 'active' : ''}
                    key={page.id}
                    onClick={(e) => changePage(page.pageNum as number, e)}
                  >
                    <a href={''}>{page.pageNum}</a>
                  </li>
                );
              } else {
                return (
                  <li key={page.id}>
                    {' '}
                    <a href={''} onClick={handleClick}>
                      ...
                    </a>
                  </li>
                );
              }
            })}
          </ol>
        </div>
        <button
          className="pagination__data__arrow-btn"
          onClick={goToNextPage}
          disabled={currentPage == pages || totalRows == 0 ? true : false}
        >
          <span>
            <FontAwesomeIcon icon={faChevronRight} />
          </span>
        </button>
      </div>
    </div>
  );
};

export default Pagination;
