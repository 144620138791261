import { useState } from 'react';
import Pageable from 'models/Pageable';

export interface PageableType {
  paginateProxy: (currentPage: number, sizePerPage: number, cb: Function) => Promise<void>;
  currentPage: number;
  totalRows: number;
  sizePerPage: number;
}

export default function usePageable() {
  // TODO useCallback
  const paginateProxy = async (currentPage: number, sizePerPage: number, cb: Function) => {
    cb(currentPage, sizePerPage);
    //setPagination({...pagination, currentPage, sizePerPage,})
  };

  // TODO useCallback
  const setPageable = (pageableResponse: Pageable<any>) => {
    const currentPage = pageableResponse.pageable.pageNumber + 1;
    const totalRows = pageableResponse.totalElements;
    const sizePerPage = pageableResponse.size;
    setPagination({ ...pagination, currentPage, totalRows, sizePerPage });
  };

  const initialPagination = {
    currentPage: 1,
    totalRows: 0,
    sizePerPage: 10,
  };

  const [pagination, setPagination] = useState(initialPagination);

  return {
    pageable: { ...pagination, paginateProxy },
    setPageable: setPageable,
  };
}
