import React, { useEffect, useState } from 'react';
import { unstable_batchedUpdates } from 'react-dom';
import Pagination from 'components/stateless/Pagination/Pagination';
import usePageable from 'utils/hooks/usePageable';
import Pageable from 'models/Pageable';
import { UserVO } from 'models/vo/UserVO';
import { requestGroupUserList } from 'utils/http/api/feature-groups';
import useMounted from 'utils/hooks/useMounted';
import { useTranslation } from 'react-i18next';
import TableHeader, { HeaderTypes } from 'components/stateless/Table/TableHeader';
import TableBorder from 'components/stateless/Table/TableBorder';
import TableBody from 'components/stateless/Table/TableBody';
import Tr from 'components/stateless/Table/Tr';
import Td from 'components/stateless/Table/Td';
import TdLink from 'components/stateless/Table/TdLink';
import { SectionTitle } from 'components/stateless/Title/SectionTitle';

const DetailGroupTable = ({ id }: { id: string }) => {
  const { pageable, setPageable } = usePageable();
  const mounted = useMounted();
  const { t } = useTranslation();
  const [userPage, setUserPage] = useState<Pageable<UserVO[]>>();

  useEffect(() => {
    if (mounted) {
      fetchUserList(pageable.currentPage, pageable.sizePerPage).then(() => console.log('fetched'));
    }
  }, [mounted]);

  async function fetchUserList(pageNumber: number, sizePerPage: number) {
    try {
      const userPageResult = await requestGroupUserList(Number(id), pageNumber, sizePerPage);
      unstable_batchedUpdates(() => {
        setUserPage(userPageResult);
        setPageable(userPageResult);
      });
    } catch (e) {
      console.log('error', e);
    }
  }

  const paginate = (pageNumber: number, sizePerPage: number) => {
    fetchUserList(pageNumber, sizePerPage);
  };

  const tableHeaders: HeaderTypes[] = [
    {
      headerText: t('text:등록일시'),
      colWidths: 100,
    },
    {
      headerText: t('text:역할명'),
      colWidths: 100,
    },
    {
      headerText: t('text:이메일'),
      colWidths: 100,
    },
    {
      headerText: t('text:상태'),
      colWidths: 100,
    },
    {
      headerText: '',
      colWidths: 50,
    },
  ];

  const renderResultTable = () => {
    return userPage?.content.map((item) => {
      return (
        <Tr key={item.id}>
          <Td data={item.createDateTime} format={'datetime'} />
          <Td data={item.name} />
          <Td data={item.email} />
          <Td data={item.activated ? t('text:Activated') : t('text:Deactivated')} />
          <TdLink path={`/admin/user/detail/${item.id}`} />
        </Tr>
      );
    });
  };

  return (
    <>
      <SectionTitle title={t('text:사용자_등록')} />
      <TableBorder>
        <TableHeader header={tableHeaders} />
        <TableBody numOfCol={tableHeaders.length}>{renderResultTable()}</TableBody>
      </TableBorder>
      <Pagination pageable={pageable} paginate={paginate} />
    </>
  );
};

export default DetailGroupTable;
