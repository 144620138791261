import React from 'react';
import { Link } from 'react-router-dom';
import Button, { ButtonSizeEnum, ButtonVariantEnum } from '../Button/Button';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowRight } from '@fortawesome/free-solid-svg-icons';

interface TdLinkProps {
  path: string;
  state?: any;
}

function TdLink({ path, state }: TdLinkProps) {
  return (
    <td className={'information-table-more'}>
      <Link to={path} state={state}>
        <Button variant={ButtonVariantEnum.OUTLINED} size={ButtonSizeEnum.SM} onClick={() => {}}>
          <FontAwesomeIcon icon={faArrowRight} />
        </Button>
      </Link>
    </td>
  );
}

export default TdLink;
