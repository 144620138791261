import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import Button, { ButtonColorEnum, ButtonSizeEnum, ButtonVariantEnum } from 'components/stateless/Button/Button';
import { requestSignIn } from 'utils/http/api/login';
import { SignInRequest } from 'utils/http/api/login/request';
import { REG_EMAIL } from 'utils/validation/regExp';
import { setSignIn } from 'utils/storage/LocalStorage';
import './LoginPage.scss';

const LoginPage = () => {
  interface SignRequest {
    email: string;
    password: string;
  }
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { register, handleSubmit } = useForm<SignRequest>({ mode: 'onSubmit', shouldFocusError: true });
  const [isInvalid, setIsInvalid] = useState<boolean>(true);

  const onSubmit = async (data: SignInRequest) => {
    setIsInvalid(true);
    try {
      const response = await requestSignIn(data);
      setSignIn(response);
      navigate('/admin/user/user-list');
    } catch (e) {
      setIsInvalid(false);
      console.log('error', e);
    }
  };

  const errorHandler = (o: object) => {
    if (Object.keys(o).length !== 0) {
      setIsInvalid(false);
    }
  };
  return (
    <div className="login-page-wrap">
      <div className="main">
        <div className="login-container">
          <form>
            <div className="login-form">
              <div className="title-wrap">
                <h2 className="login-form-title">{t('text:Sign_in_to_Website')}</h2>
                <h3 className="sub-title">BackOffice for fin2b</h3>
                {!isInvalid ? <div className="false">{t('text:이메일과_패스워드를_확인해주세요')}</div> : ''}
              </div>
              <div className="input-wrap">
                <input {...register('email', { required: true, pattern: REG_EMAIL, max: 30 })} placeholder="email" />

                <input type="password" {...register('password', { required: true, max: 20 })} placeholder="password" />
              </div>
              <Button
                onClick={handleSubmit(onSubmit, errorHandler)}
                size={ButtonSizeEnum.LG}
                variant={ButtonVariantEnum.FILLED}
                color={ButtonColorEnum.BLUE}
                type={'submit'}
              >
                {t('text:SIGN_IN')}
              </Button>
            </div>
          </form>
        </div>
        <div className="content-container">
          <h3 className="content-title">{t('text:Welcome')}</h3>
          <div className="description">
            {t('text:핀투비에_오신_것을_환영합니다')}
            <br />
            {t(`text:해당_사이트는_핀투비_백오피스_사이트입니다`)}
          </div>
        </div>
      </div>
    </div>
  );
};
export default LoginPage;
