import React, { useEffect, useState } from 'react';
import { unstable_batchedUpdates } from 'react-dom';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft, faArrowRight } from '@fortawesome/free-solid-svg-icons';
import useMounted from 'utils/hooks/useMounted';
import { requestUserInfo, requestChangeActivated, requestResetPassword } from 'utils/http/api/accounts';
import { requestAssignedGroupList, requestUnassignedGroupList, requestPutFeatureGroup } from 'utils/http/api/accounts';
import Pageable from 'models/Pageable';
import { UserVO } from 'models/vo/UserVO';
import { GroupVO } from 'models/vo/GroupVo';
import { HeaderTitle } from 'components/stateless/Title/HeaderTitle';
import { SectionTitle } from 'components/stateless/Title/SectionTitle';
import Button, { ButtonSizeEnum, ButtonVariantEnum, ButtonColorEnum } from 'components/stateless/Button/Button';
import '../../../scss/admin/Detail.scss';

const DetailUser = () => {
  const { userId } = useParams();
  const { t } = useTranslation();
  const mounted = useMounted();
  const navigate = useNavigate();
  const [userInfo, setUserInfo] = useState<UserVO>();
  const [unassignedGroupList, setUnassignedGroupList] = useState<Pageable<GroupVO[]>>();
  const [assignedGroupList, setAssignedGroupList] = useState<Pageable<GroupVO[]>>();
  const [originalUnassignedGroupList, setOriginalUnassignedGroupList] = useState<Pageable<GroupVO[]>>();
  const [originalAssignedGroupList, setOriginalAssignedGroupList] = useState<Pageable<GroupVO[]>>();
  const [middleUnassignedGroupList, setMiddleUnassignedGroupList] = useState<GroupVO[]>([]);
  const [middleAssignedGroupList, setMiddleAssignedGroupList] = useState<GroupVO[]>([]);
  const [isEdit, setIsEdit] = useState<boolean>(false);

  useEffect(() => {
    if (mounted) {
      fetchAll(userId as string, 1, 1000);
    }
  }, [mounted]);

  async function fetchAll(id: string, pageNumber: number, sizePerPage: number) {
    try {
      const responseUserInfoData = await requestUserInfo(Number(id));
      const responseAssignedGroupList = await requestAssignedGroupList(Number(id), pageNumber, sizePerPage);
      const responseUnassignedGroupList = await requestUnassignedGroupList(Number(id), pageNumber, sizePerPage);
      unstable_batchedUpdates(() => {
        setUserInfo(responseUserInfoData);
        setAssignedGroupList(responseAssignedGroupList);
        setUnassignedGroupList(responseUnassignedGroupList);
        setOriginalAssignedGroupList(responseAssignedGroupList);
        setOriginalUnassignedGroupList(responseUnassignedGroupList);
      });
    } catch (e) {
      console.log('error', e);
    }
  }

  const onClickRequestResetPassword = async (id: string) => {
    if (confirm(t('text:비밀번호를_초기화하시겠습니까?'))) {
      try {
        await requestResetPassword(Number(id));
        alert(t('text:비밀번호가_초기화_되었습니다'));
      } catch (e) {
        console.log('error', e);
      }
    } else {
      return false;
    }
  };

  const onClickRequestChangeActivated = async (id: string, activated: boolean) => {
    if (confirm(t('text:상태를_변경하시겠습니까?'))) {
      try {
        const responseData = await requestChangeActivated(Number(id), !activated);
        setUserInfo(responseData);
        alert(t('text:상태가_변경되었습니다'));
      } catch (e) {
        console.log('error', e);
      }
    } else {
      return false;
    }
  };

  const setUnassignedMiddleList = (id: number) => {
    if (isEdit) {
      const groupInfo = unassignedGroupList?.content.find((group) => group.featureGroupId === id) as GroupVO;
      const isIncludeMiddle = middleUnassignedGroupList.find((group) => group.featureGroupId === id) as GroupVO;
      if (middleAssignedGroupList.length !== 0) {
        unstable_batchedUpdates(() => {
          setMiddleAssignedGroupList([]);
          setMiddleUnassignedGroupList([...middleUnassignedGroupList, groupInfo]);
        });
      } else if (isIncludeMiddle) {
        setMiddleUnassignedGroupList(middleUnassignedGroupList.filter((group) => group.featureGroupId !== id));
      } else {
        setMiddleUnassignedGroupList([...middleUnassignedGroupList, groupInfo]);
      }
    }
  };
  const setAssignedMiddleList = (id: number) => {
    if (isEdit) {
      const groupInfo = assignedGroupList?.content.find((group) => group.featureGroupId === id) as GroupVO;
      const isIncludeMiddle = middleAssignedGroupList.find((group) => group.featureGroupId === id) as GroupVO;
      if (middleUnassignedGroupList.length !== 0) {
        unstable_batchedUpdates(() => {
          setMiddleUnassignedGroupList([]);
          setMiddleAssignedGroupList([...middleAssignedGroupList, groupInfo]);
        });
      } else if (isIncludeMiddle) {
        setMiddleAssignedGroupList(middleAssignedGroupList.filter((group) => group.featureGroupId !== id));
      } else {
        setMiddleAssignedGroupList([...middleAssignedGroupList, groupInfo]);
      }
    }
  };

  const isSelected = (featureGroupId: number) => {
    const isIncludeAssignedMiddle = middleAssignedGroupList.find(
      (group) => group.featureGroupId === featureGroupId,
    ) as GroupVO;
    const isIncludeUnassignedMiddle = middleUnassignedGroupList.find(
      (group) => group.featureGroupId === featureGroupId,
    ) as GroupVO;
    if (isIncludeAssignedMiddle || isIncludeUnassignedMiddle) return true;
    else return false;
  };
  const renderNoDataAvailable = () => {
    return <li className="border-none">{t('text:사용가능한_데이터가_없습니다')}</li>;
  };

  const renderResultGroupList = (GroupList: Pageable<GroupVO[]>, setMiddleList: Function) => {
    return GroupList?.content.length !== 0
      ? GroupList?.content.map((group) => {
          return (
            <li
              className={isSelected(group.featureGroupId) ? 'on' : ''}
              key={group.featureGroupId}
              onClick={() => setMiddleList(group.featureGroupId)}
            >
              {group.featureGroupName}
            </li>
          );
        })
      : renderNoDataAvailable();
  };

  const moveToAssigned = () => {
    if (middleAssignedGroupList.length === 0) {
      unstable_batchedUpdates(() => {
        setAssignedGroupList({
          ...(assignedGroupList as Pageable<GroupVO[]>),
          content: [...middleUnassignedGroupList, ...(assignedGroupList?.content as GroupVO[])],
        });
        setUnassignedGroupList({
          ...(unassignedGroupList as Pageable<GroupVO[]>),
          content: (unassignedGroupList?.content as GroupVO[]).filter((group) => {
            for (let i = 0; i < middleUnassignedGroupList.length; i++) {
              if (middleUnassignedGroupList[i].featureGroupId === group.featureGroupId) return false;
            }
            return true;
          }),
        });
        setMiddleAssignedGroupList([...middleUnassignedGroupList]);
        setMiddleUnassignedGroupList([]);
      });
    }
  };
  const moveToUnassigned = () => {
    if (middleUnassignedGroupList.length === 0) {
      unstable_batchedUpdates(() => {
        setUnassignedGroupList({
          ...(unassignedGroupList as Pageable<GroupVO[]>),
          content: [...middleAssignedGroupList, ...(unassignedGroupList?.content as GroupVO[])],
        });
        setAssignedGroupList({
          ...(assignedGroupList as Pageable<GroupVO[]>),
          content: (assignedGroupList?.content as GroupVO[]).filter((group) => {
            for (let i = 0; i < middleAssignedGroupList.length; i++) {
              if (middleAssignedGroupList[i].featureGroupId === group.featureGroupId) return false;
            }
            return true;
          }),
        });
        setMiddleUnassignedGroupList([...middleAssignedGroupList]);
        setMiddleAssignedGroupList([]);
      });
    }
  };

  const goBack = () => {
    navigate(-1);
  };
  const onClickCancel = () => {
    if (confirm(t('text:수정을_취소하시겠습니까?'))) {
      unstable_batchedUpdates(() => {
        setIsEdit(false);
        setUnassignedGroupList({ ...(originalUnassignedGroupList as Pageable<GroupVO[]>) });
        setAssignedGroupList({ ...(originalAssignedGroupList as Pageable<GroupVO[]>) });
        setMiddleAssignedGroupList([]);
        setMiddleUnassignedGroupList([]);
      });
    } else {
      return false;
    }
  };

  const postFeatureGroup = async () => {
    const featureGroupIdList = assignedGroupList?.content.map((group) => group.featureGroupId) as number[];
    if (confirm(t('text:수정하시겠습니까?'))) {
      try {
        await requestPutFeatureGroup({ accountId: Number(userId), featureGroupIdList });
        alert(t('text:수정되었습니다'));
        setIsEdit(false);
        setOriginalAssignedGroupList({ ...(assignedGroupList as Pageable<GroupVO[]>) });
        setOriginalUnassignedGroupList({ ...(unassignedGroupList as Pageable<GroupVO[]>) });
        setMiddleAssignedGroupList([]);
        setMiddleUnassignedGroupList([]);
      } catch (e) {
        console.log('error', e);
      }
    } else return false;
  };

  return (
    <div className="content-wrap">
      <HeaderTitle title={t('text:사용자_상세')}>
        <Button variant={ButtonVariantEnum.OUTLINED} size={ButtonSizeEnum.SM} onClick={goBack} className="go-back-btn">
          <FontAwesomeIcon icon={faArrowLeft} />
        </Button>
      </HeaderTitle>
      <div className="content-area">
        <SectionTitle title={t('text:사용자_정보')} />
        <div className="info-field">
          <div className="row">
            <div className="row-content">
              <div className="label">{t('text:등록일시')}</div>
              <div className="uneditable-input">
                {userInfo?.createDateTime
                  ? t('format:datetime', { value: userInfo?.createDateTime, key: 'date' })
                  : '-'}
              </div>
            </div>
            <div className="row-content">
              <div className="label">{t('text:이메일')}</div>
              <div className="uneditable-input">{userInfo?.email ? userInfo?.email : '-'}</div>
            </div>
          </div>
          <div className="row">
            <div className="row-content">
              <div className="label">{t('text:사용자_이름')}</div>
              <div className="uneditable-input">{userInfo?.name ? userInfo.name : '-'}</div>
            </div>
            <div className="row-content">
              <div className="label">{t('text:비밀번호')}</div>
              <div className="include-btn-input">
                ********
                <Button
                  variant={ButtonVariantEnum.OUTLINED}
                  size={ButtonSizeEnum.SM}
                  onClick={() => onClickRequestResetPassword(userId as string)}
                >
                  {t('text:비밀번호_초기화')}
                </Button>
              </div>
            </div>
          </div>
          <div className="row-content">
            <div className="label">{t('text:상태')}</div>
            <div className="include-btn-input">
              {userInfo?.activated ? t('text:활성화') : t('text:비활성화')}
              <Button
                variant={ButtonVariantEnum.OUTLINED}
                size={ButtonSizeEnum.SM}
                onClick={() => onClickRequestChangeActivated(userId as string, userInfo?.activated as boolean)}
              >
                {t('text:상태변경')}
              </Button>
            </div>
          </div>
        </div>
        <div className="content-field">
          <div className="content-setting">
            <SectionTitle title={t('text:할당되지_않은_그룹')} />
            <div className="top-list">
              <ul>
                <li className="list-title">{t('text:그룹명')}</li>
                {renderResultGroupList(unassignedGroupList as Pageable<GroupVO[]>, setUnassignedMiddleList)}
              </ul>
            </div>
          </div>
          <div className="button-wrap">
            <Button
              variant={ButtonVariantEnum.OUTLINED}
              size={ButtonSizeEnum.SM}
              onClick={moveToUnassigned}
              disabled={!isEdit || middleAssignedGroupList.length === 0}
            >
              <FontAwesomeIcon icon={faArrowLeft} />
            </Button>
            <Button
              variant={ButtonVariantEnum.OUTLINED}
              size={ButtonSizeEnum.SM}
              onClick={moveToAssigned}
              disabled={!isEdit || middleUnassignedGroupList.length === 0}
            >
              <FontAwesomeIcon icon={faArrowRight} />
            </Button>
          </div>
          <div className="content-setting">
            <SectionTitle title={t('text:할당된_그룹')} className={'section-title-btn'}>
              {!isEdit ? (
                <div className="edit-btn-wrap">
                  <Button variant={ButtonVariantEnum.OUTLINED} size={ButtonSizeEnum.SM} onClick={() => setIsEdit(true)}>
                    {t('text:수정')}
                  </Button>
                </div>
              ) : (
                <div className="edit-btn-wrap">
                  <Button
                    variant={ButtonVariantEnum.OUTLINED}
                    size={ButtonSizeEnum.SM}
                    onClick={onClickCancel}
                    color={ButtonColorEnum.SECONDARY}
                  >
                    {t('text:취소')}
                  </Button>
                  <Button
                    variant={ButtonVariantEnum.OUTLINED}
                    size={ButtonSizeEnum.SM}
                    onClick={postFeatureGroup}
                    style={{ width: '60px' }}
                  >
                    {t('text:저장')}
                  </Button>
                </div>
              )}
            </SectionTitle>
            <div className="top-list">
              <ul>
                <li className="list-title">{t('text:그룹명')}</li>
                {renderResultGroupList(assignedGroupList as Pageable<GroupVO[]>, setAssignedMiddleList)}
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DetailUser;
