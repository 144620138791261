import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import dayjs from 'dayjs';
import textTranslationEN from '../locales/translation/en/text.json';
import textTranslationKO from '../locales/translation/ko/text.json';
import formatTranslationEN from '../locales/translation/en/format.json';
import formatTranslationKO from '../locales/translation/ko/format.json';
import { localeNumberFormatter } from './localeNumberFormatter';

export enum languageType {
  EN_USA = 'en_usa',
  EN_INDIA = 'en_india',
  KO = 'ko',
  VN = 'vn',
}

const resources = {
  // language : {
  //   namespace : JSON FIle
  // }
  en_usa: {
    text: textTranslationEN,
    format: formatTranslationEN,
  },
  ko: {
    text: textTranslationKO,
    format: formatTranslationKO,
  },
};

const nsSeparator = ':';

i18n.use(initReactI18next).init({
  resources,
  ns: ['format', 'text'],
  lng: languageType.KO,
  keySeparator: '.',
  nsSeparator: nsSeparator,
  interpolation: {
    escapeValue: false,
    format: function (value, formatting, lng, options) {
      if (options?.ns == 'format') {
        if (options?.key == 'date') return dayjs(value).format(formatting);
        else if (options?.key == 'number') return localeNumberFormatter(value);
        return value.toString();
      } else return value;
    },
  },
});

export default i18n;
