import { SignInResponse } from 'models/SignModel';

const SIGN_IN = 'SIGN_IN';

export function setSignIn(signInModel: SignInResponse) {
  window.localStorage.setItem(SIGN_IN, JSON.stringify(signInModel));
}

export function getSignIn(): SignInResponse | null {
  const jsonString = window.localStorage.getItem(SIGN_IN);
  try {
    return jsonString ? JSON.parse(jsonString) : null;
  } catch (e) {
    console.error('wrong json format');
    return null;
  }
}

export function clearLocal() {
  window.localStorage.clear();
}
