import Pageable from 'models/Pageable';
import http from 'utils/http';
import { FeatureVO } from 'models/vo/FeatureVO';

export async function requestFeatureList(pageNumber: number, rowCount: number): Promise<Pageable<FeatureVO[]>> {
  const response = await http.get<Pageable<FeatureVO[]>>('/v1/features', {
    pageNumber,
    rowCount,
  });
  return response;
}
