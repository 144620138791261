export enum ROLE {
  ROLE_ADMIN = 'ROLE_ADMIN',
}

export enum BackGroundType {
  'WHITE' = 'bg-white',
  'Gray' = 'bg-sub100',
  'DarkGray' = 'bg-sub200',
  null = '',
}
