import React from 'react';
import { Routes, Route } from 'react-router-dom';
import Header from 'components/stateless/Header/Header';
import LeftMenu from 'components/stateless/LeftMenu/LeftMenu';
import PrivateRoute from '../guard/PrivateRoute';
import UserList from 'pages/admin/userManagement/UserList';
import RegisterUser from 'pages/admin/userManagement/RegisterUser';
import DetailUser from 'pages/admin/userManagement/DetailUser';
import RegisterGroup from 'pages/admin/groupManagement/RegisterGroup';
import GroupList from 'pages/admin/groupManagement/GroupList';
import DetailGroup from 'pages/admin/groupManagement/DetailGroup';
import { adminMenuInfo } from './AdminMenuInfo';

const AdminRouter = () => {
  const menuInfo = adminMenuInfo();
  return (
    <>
      <Header />
      <LeftMenu menuInfo={menuInfo} />
      <div className="section-container">
        <div className="bg-gray-wrap">
          <Routes>
            <Route element={<PrivateRoute />}>
              <Route path="user/user-list" element={<UserList />} />
              <Route path="user/register" element={<RegisterUser />} />
              <Route path="user/detail/:userId" element={<DetailUser />} />
              <Route path="group/register" element={<RegisterGroup />} />
              <Route path="group/group-list" element={<GroupList />} />
              <Route path="group/detail/:groupId" element={<DetailGroup />} />
            </Route>
          </Routes>
        </div>
      </div>
    </>
  );
};
export default AdminRouter;
