import axios, { AxiosInstance, AxiosResponse } from 'axios';
import qs from 'qs';
import mock from './mock/index';
import { HttpError } from 'utils/error/HttpError';
import { CommonResponse, ContentType } from 'utils/http/types';
import { stringToBoolean } from 'utils/convert';

// axios instance 생성
const axiosInstance: AxiosInstance = axios.create();

// mock setting
if (process.env.REACT_APP_IS_MOCK && stringToBoolean(process.env.REACT_APP_IS_MOCK)) {
  mock(axiosInstance);
}

axiosInstance.defaults.baseURL = process.env.REACT_APP_BASE_URL;
axiosInstance.defaults.headers.post['Content-Type'] = ContentType.JSON;

axiosInstance.interceptors.request.use(
  function (config) {
    if (process.env.REACT_APP_IS_MOCK && stringToBoolean(process.env.REACT_APP_IS_MOCK)) {
      console.log({ method: config.method, url: config.url, data: config.data });
    }
    // 요청을 보내기 전에 수행할 일
    return config;
  },
  function (error) {
    // 오류 요청을 보내기전 수행할 일
    return Promise.reject(error);
  },
);

// 응답 인터셉터 추가
axiosInstance.interceptors.response.use(
  function (response) {
    if (process.env.REACT_APP_IS_MOCK && stringToBoolean(process.env.REACT_APP_IS_MOCK)) {
      console.log(response.data);
    }

    // 응답 데이터를 가공
    return response;
  },
  function (error) {
    // 오류 응답 처리
    return Promise.reject(error);
  },
);

function convertToFormData(data: { [index: string]: any }) {
  const formData: FormData = new FormData();
  Object.keys(data).forEach((key) => {
    if (data[key] instanceof Array || data[key] instanceof FileList) {
      const size = data[key].length;
      for (let i = 0; i < size; i++) {
        if (data[key][i] instanceof File) {
          formData.append(key, data[key][i]);
        } else if (typeof data[key][i] === 'string') {
          formData.append(`${key}[${i}]`, data[key][i]);
        } else if (data[key][i] instanceof FileList) {
          const fileSize = data[key][i].length;
          if (fileSize === 0) {
            formData.append(key, new Blob());
          } else {
            for (let j = 0; j < fileSize; j++) {
              formData.append(key, data[key][i][j]);
            }
          }
        } else if (data[key][i] instanceof Object) {
          for (const [objKey, value] of Object.entries(data[key][i])) {
            formData.append(`${key}[${i}].${objKey}`, value as string);
          }
        } else {
          formData.append(key, JSON.stringify(data[key][i]));
        }
      }
    } else {
      if (data[key] !== null) {
        formData.append(key, data[key]);
      }
    }
  });
  return formData;
}

const requestDTOParser = (data: any, literallyTargets?: Array<any>): void => {
  if (data) {
    const dataKeyList = Object.keys(data);
    if (!literallyTargets || literallyTargets.length <= 0) {
      dataKeyList.map((key) => {
        if (data[key] === '') data[key] = null;
      });
    } else {
      dataKeyList.map((key) => {
        if (data[key] === '' && literallyTargets.indexOf(key) == -1) {
          data[key] = null;
        }
      });
    }
  }
};

const returnResponseData = (axiosResponse: AxiosResponse<any>): Promise<any> => {
  if (axiosResponse.status === 200 || 201) {
    return axiosResponse.data;
  } else {
    throw new HttpError(String(axiosResponse.status), axiosResponse.data, axiosResponse.statusText);
  }
};

class http {
  async download(url: string, data?: any): Promise<void> {
    const queryParam = qs.stringify(data);
    try {
      const axiosResponse: AxiosResponse = await axiosInstance.get(!queryParam ? url : `${url}?${queryParam}`, {
        responseType: 'blob',
      });
      // 에러 발생시에는 데이터 타입이 JSON 형태로 내려옴
      if (axiosResponse.data.type == ContentType.JSON) {
        const commonResponse: CommonResponse = JSON.parse(await axiosResponse.data.text());
        throw new HttpError(commonResponse.code, commonResponse.data, commonResponse.message);
      } else {
        // 정상 응답
        const contentDisposition = axiosResponse.headers['content-disposition'];
        const fileName = contentDisposition?.match(/filename[*]=UTF-8''(.+)/)?.[1];
        if (fileName) {
          // TODO :: need test
          const url = window.URL.createObjectURL(new Blob([axiosResponse.data]));
          const link = document.createElement('a');
          link.href = url;
          link.setAttribute('download', fileName);
          document.body.appendChild(link);
          link.click();
        }
      }
    } catch (e) {
      console.log(e);
    }
  }

  async get<T>(url: string, data?: any): Promise<T> {
    if (data?.pageNumber) {
      data.pageNumber = data.pageNumber - 1;
    }
    const queryParam = qs.stringify(data);
    const axiosResponse: AxiosResponse<T> = await axiosInstance.get(!queryParam ? url : `${url}?${queryParam}`);
    return returnResponseData(axiosResponse);
  }

  async post<T, Q = Record<string, unknown>>(
    url: string,
    data?: any,
    contentType?: ContentType,
    literallyTargets?: Array<keyof Q>,
  ): Promise<T> {
    const options = {
      headers: { 'Content-Type': contentType ? contentType : ContentType.JSON },
    };

    requestDTOParser(data, literallyTargets);

    if (contentType == ContentType.MULTIPART) {
      data = convertToFormData(data);
    }

    if (contentType === ContentType.URLENCODED) {
      data = qs.stringify(data);
    }

    const axiosResponse: AxiosResponse<T> = await axiosInstance.post(`${url}`, data, options);
    return returnResponseData(axiosResponse);
  }

  async put<T, Q = Record<string, unknown>>(
    url: string,
    data?: any,
    contentType?: ContentType,
    literallyTargets?: Array<keyof Q>,
  ): Promise<T> {
    const options = {
      headers: { 'Content-Type': contentType ? contentType : ContentType.JSON },
    };

    requestDTOParser(data, literallyTargets);

    if (contentType == ContentType.MULTIPART) {
      data = convertToFormData(data);
    }

    if (contentType === ContentType.URLENCODED) {
      data = qs.stringify(data);
    }

    const axiosResponse: AxiosResponse<T> = await axiosInstance.put(`${url}`, data, options);

    return returnResponseData(axiosResponse);
  }

  async delete<T>(url: string, data?: any): Promise<T> {
    const axiosResponse: AxiosResponse<T> = await axiosInstance.delete(`${url}`, { data });
    return returnResponseData(axiosResponse);
  }
}

export default new http();
