import { CommonResponseCode, ResponseCodeType } from 'enums/ResponseCode';

export enum ContentType {
  JSON = 'application/json',
  MULTIPART = 'multipart/form-data',
  URLENCODED = 'application/x-www-form-urlencoded',
  OCTET_STREAM = 'application/octet-stream',
  TEXT = 'text/plain',
  XLS = 'application/vnd.ms-excel',
  XLSX = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
}

export interface SuccessResponse<T> {
  code: CommonResponseCode.SUCCESS;
  data: T;
  message: string;
}

export interface NotSuccessResponse {
  code: Exclude<ResponseCodeType, CommonResponseCode.SUCCESS>;
  data: null;
  message: string;
}

export type CommonResponse<T = unknown> = SuccessResponse<T> | NotSuccessResponse;
