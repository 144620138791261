import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { unstable_batchedUpdates } from 'react-dom';
import { useTranslation } from 'react-i18next';
import { UserVO } from 'models/vo/UserVO';
import Pageable from 'models/Pageable';
import { GroupVO } from 'models/vo/GroupVo';
import { HeaderTitle } from 'components/stateless/Title/HeaderTitle';
import { SectionTitle } from 'components/stateless/Title/SectionTitle';
import useMounted from 'utils/hooks/useMounted';
import { REG_EMAIL } from 'utils/validation/regExp';
import { UserRegisterRequest } from 'utils/http/api/accounts/request';
import { requestGroupList } from 'utils/http/api/feature-groups';
import { requestRegisterUser } from 'utils/http/api/accounts';
import Button, { ButtonColorEnum, ButtonSizeEnum, ButtonVariantEnum } from 'components/stateless/Button/Button';
import '../../../scss/admin/Register.scss';

const RegisterUser = () => {
  const { t } = useTranslation();
  const mounted = useMounted();
  const navigate = useNavigate();
  const { register, handleSubmit, reset, setValue, watch } = useForm<UserRegisterRequest>({
    mode: 'onSubmit',
    shouldFocusError: true,
    defaultValues: { featureGroupIdList: [] },
  });
  const { featureGroupIdList } = watch();
  const [groupPage, setGroupPage] = useState<Pageable<GroupVO[]>>();

  useEffect(() => {
    if (mounted) {
      fetchGroupList(1, 1000).then(() => console.log('fetch'));
    }
  }, [mounted]);

  const fetchGroupList = async (pageNumber: number, sizPerPage: number) => {
    try {
      const groupListResult = await requestGroupList(pageNumber, sizPerPage);
      unstable_batchedUpdates(() => {
        setGroupPage(groupListResult);
      });
    } catch (e) {
      console.log('error', e);
    }
  };
  const handleCheck = (e: React.ChangeEvent<HTMLInputElement>, id: number) => {
    if (e.target.checked) {
      setValue('featureGroupIdList', [...featureGroupIdList, id]);
    } else {
      setValue(
        'featureGroupIdList',
        featureGroupIdList?.filter((check_id) => check_id !== id),
      );
    }
  };
  const isChecked = (id: number) => {
    if (featureGroupIdList?.includes(id)) return true;
    else return false;
  };

  const postRegisterUser = async (data: UserRegisterRequest) => {
    try {
      const responseUserInfo: UserVO = await requestRegisterUser(data);
      alert(t('text:등록되었습니다'));
      reset({ email: '', featureGroupIdList: [] });
      navigate(`/admin/user/detail/${responseUserInfo.id}`);
    } catch (e) {
      console.log('error', e);
      alert(t('text:이메일이_중복되었습니다'));
    }
  };
  const errorHandler = (o: any) => {
    if (o.email.type === 'required') {
      alert(t('text:이메일을_입력해주세요'));
    } else if (o.email.type === 'pattern') {
      alert(t('text:이메일_형식을_지켜주세요'));
    }
  };

  const onClickCancel = () => {
    if (confirm(t('text:등록을_취소하시겠습니까?'))) {
      navigate('/admin/user/user-list');
    } else return false;
  };

  const renderResultGroup = () => {
    return groupPage?.content.map((item) => {
      return (
        <li key={item.featureGroupId}>
          <input
            type="checkbox"
            checked={isChecked(item.featureGroupId)}
            onChange={(e) => handleCheck(e, item.featureGroupId)}
          />
          {item.featureGroupName}
        </li>
      );
    });
  };

  return (
    <div className="content-wrap">
      <HeaderTitle title={t('text:사용자_등록')} />
      <div className="content-area">
        <SectionTitle title={t('text:사용자_정보')} />
        <div className="info-field ">
          <p>{t('text:이메일')}</p>
          <input
            {...register('email', { required: true, pattern: REG_EMAIL, max: 30 })}
            placeholder={t('text:이메일을_입력해주세요')}
          />
        </div>
        <div className="setting-field">
          <SectionTitle title={t('text:역할_그룹_설정')} />
          <div className="top-list">
            <ul>
              <li className="list-title">{t('text:역할_그룹명')}</li>
              {renderResultGroup()}
            </ul>
          </div>
        </div>
        <div className="button-wrap">
          <Button
            onClick={onClickCancel}
            size={ButtonSizeEnum.MD}
            variant={ButtonVariantEnum.OUTLINED}
            color={ButtonColorEnum.SECONDARY}
          >
            {t('text:취소')}
          </Button>
          <Button
            onClick={handleSubmit(postRegisterUser, errorHandler)}
            size={ButtonSizeEnum.MD}
            variant={ButtonVariantEnum.OUTLINED}
            color={ButtonColorEnum.PRIMARY}
          >
            {t('text:등록')}
          </Button>
        </div>
      </div>
    </div>
  );
};

export default RegisterUser;
