import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { unstable_batchedUpdates } from 'react-dom';
import { useTranslation } from 'react-i18next';
import Pageable from 'models/Pageable';
import { FeatureVO } from 'models/vo/FeatureVO';
import { GroupVO } from 'models/vo/GroupVo';
import { HeaderTitle } from 'components/stateless/Title/HeaderTitle';
import { SectionTitle } from 'components/stateless/Title/SectionTitle';
import { requestRegisterGroup } from 'utils/http/api/feature-groups';
import { requestFeatureList } from 'utils/http/api/features';
import { GroupRegisterRequest } from 'utils/http/api/feature-groups/request';
import useMounted from 'utils/hooks/useMounted';
import Button, { ButtonColorEnum, ButtonSizeEnum, ButtonVariantEnum } from 'components/stateless/Button/Button';
import '../../../scss/admin/Register.scss';

const RegisterGroup = () => {
  const { t } = useTranslation();
  const mounted = useMounted();
  const navigate = useNavigate();
  const [featurePage, setFeaturePage] = useState<Pageable<FeatureVO[]>>();
  const { register, handleSubmit, reset, setValue, watch } = useForm<GroupRegisterRequest>({
    mode: 'onSubmit',
    shouldFocusError: true,
    defaultValues: { featureIdList: [] },
  });
  const { featureIdList } = watch();

  useEffect(() => {
    if (mounted) {
      fetchGroupList(1, 1000).then(() => console.log('fetch'));
    }
  }, [mounted]);

  const fetchGroupList = async (pageNumber: number, sizPerPage: number) => {
    try {
      const featureListResult = await requestFeatureList(pageNumber, sizPerPage);
      unstable_batchedUpdates(() => {
        setFeaturePage(featureListResult);
      });
    } catch (e) {
      console.log('error', e);
    }
  };
  const handleCheck = (e: React.ChangeEvent<HTMLInputElement>, id: number) => {
    if (e.target.checked) {
      setValue('featureIdList', [...featureIdList, id]);
    } else {
      setValue(
        'featureIdList',
        featureIdList?.filter((check_id) => check_id !== id),
      );
    }
  };
  const isChecked = (id: number) => {
    if (featureIdList?.includes(id)) return true;
    else return false;
  };

  const postRegisterGroup = async (data: GroupRegisterRequest) => {
    try {
      const responseGroupInfo: GroupVO = await requestRegisterGroup(data);
      alert(t('text:등록되었습니다'));
      reset({ name: '', featureIdList: [] });
      navigate(`/admin/group/detail/${responseGroupInfo.featureGroupId}`);
    } catch (e) {
      console.log('error', e);
      alert(t('text:역할명이_중복되었습니다'));
    }
  };
  const errorHandler = (o: any) => {
    if (o.name.type === 'required') {
      alert(t('text:역할명을_입력해주세요'));
      console.log(typeof o.name.ref);
    }
  };

  const onClickCancel = () => {
    if (confirm(t('text:등록을_취소하시겠습니까?'))) {
      navigate('/admin/group/group-list');
    } else return false;
  };

  const renderResultFeatures = () => {
    return featurePage?.content.map((item) => {
      return (
        <li key={item.id}>
          <input type="checkbox" checked={isChecked(item.id)} onChange={(e) => handleCheck(e, item.id)} />
          {item.name}
        </li>
      );
    });
  };

  return (
    <div className="content-wrap">
      <HeaderTitle title={t('text:역할_그룹_등록')} />
      <div className="content-area">
        <SectionTitle title={t('text:역할_그룹_정보')} />
        <div className="info-field ">
          <p>{t('text:역할명')}</p>
          <input {...register('name', { required: true, max: 20 })} placeholder={t('text:역할명을_입력해주세요')} />
        </div>
        <div className="setting-field">
          <SectionTitle title={t('text:기능_할당')} />
          <div className="top-list">
            <ul>
              <li className="list-title">{t('text:기능명')}</li>
              {renderResultFeatures()}
            </ul>
          </div>
        </div>
        <div className="button-wrap">
          <Button
            onClick={onClickCancel}
            size={ButtonSizeEnum.MD}
            variant={ButtonVariantEnum.OUTLINED}
            color={ButtonColorEnum.SECONDARY}
          >
            {t('text:취소')}
          </Button>
          <Button
            onClick={handleSubmit(postRegisterGroup, errorHandler)}
            size={ButtonSizeEnum.MD}
            variant={ButtonVariantEnum.OUTLINED}
            color={ButtonColorEnum.PRIMARY}
          >
            {t('text:등록')}
          </Button>
        </div>
      </div>
    </div>
  );
};

export default RegisterGroup;
