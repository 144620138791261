import React from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';
import reportWebVitals from './reportWebVitals';
import RootRouter from 'router/RootRouter';
import './locales/i18n';
import './scss/common.scss'


const root = ReactDOM.createRoot(document.getElementById('wrap') as HTMLElement);
root.render(
  <React.StrictMode>
  <BrowserRouter>
  <RootRouter/>
  </BrowserRouter>
  </React.StrictMode>,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
