import React from 'react';
import './Table.scss';

interface PropsType {
  className?: string;
  children?: JSX.Element | (JSX.Element | null | undefined | boolean)[] | null | undefined | boolean;
}

function TableBorder({ children, className }: PropsType) {
  return (
    <div className="table-overflow-scroll">
      <table className={`table-border ` + `${className}`}>{children}</table>
    </div>
  );
}

export default TableBorder;
