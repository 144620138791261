import Pageable from 'models/Pageable';
import http from 'utils/http';
import { GroupVO } from 'models/vo/GroupVo';
import { UserVO } from 'models/vo/UserVO';
import { FeatureVO } from 'models/vo/FeatureVO';
import { GroupRegisterRequest, GroupInfoPutRequest, FeaturePutRequest } from './request';

export async function requestGroupList(pageNumber: number, rowCount: number): Promise<Pageable<GroupVO[]>> {
  const response = await http.get<Pageable<GroupVO[]>>('/v1/feature-groups', {
    pageNumber,
    rowCount,
  });
  return response;
}

export async function requestRegisterGroup(data: GroupRegisterRequest): Promise<GroupVO> {
  const response = await http.post<GroupVO>('/v1/feature-groups', data);
  return response;
}

export async function requestGroupInfo(id: number): Promise<GroupVO> {
  const response = await http.get<GroupVO>(`/v1/feature-groups/${id}`);
  return response;
}

export async function requestUnassignedFeatureList(
  id: number,
  pageNumber: number,
  rowCount: number,
): Promise<FeatureVO[]> {
  const response = await http.get<FeatureVO[]>(`/v1/feature-groups/${id}/filter:unassigned-features`, {
    pageNumber,
    rowCount,
  });
  return response;
}

export async function requestAssignedFeatureList(
  id: number,
  pageNumber: number,
  rowCount: number,
): Promise<FeatureVO[]> {
  const response = await http.get<FeatureVO[]>(`/v1/feature-groups/${id}/filter:assigned-features`, {
    pageNumber,
    rowCount,
  });
  return response;
}

export async function requestPutGroupInfo(data: GroupInfoPutRequest): Promise<GroupVO> {
  const response = await http.put<GroupVO>('/v1/feature-groups', data);
  return response;
}

export async function requestPutGroupFeature(data: FeaturePutRequest) {
  await http.post<GroupVO>('/v1/feature-groups/assign:features', data);
}

export async function requestGroupUserList(
  id: number,
  pageNumber: number,
  rowCount: number,
): Promise<Pageable<UserVO[]>> {
  const response = await http.get<Pageable<UserVO[]>>(`/v1/feature-groups/${id}/accounts`, {
    pageNumber,
    rowCount,
  });
  return response;
}

export async function requestDeleteGroup(id: number) {
  await http.delete(`/v1/feature-groups/${id}`);
}
