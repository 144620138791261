import Pageable from 'models/Pageable';
import http from 'utils/http';
import { GroupVO } from 'models/vo/GroupVo';
import { UserVO } from 'models/vo/UserVO';
import { FeatureGroupPutRequest, UserRegisterRequest } from './request';

export async function requestUserList(pageNumber: number, rowCount: number): Promise<Pageable<UserVO[]>> {
  const response = await http.get<Pageable<UserVO[]>>('/v1/accounts', {
    pageNumber,
    rowCount,
  });
  return response;
}

export async function requestRegisterUser(data: UserRegisterRequest): Promise<UserVO> {
  const response = await http.post<UserVO>('/v1/accounts', data);
  return response;
}

export async function requestUserInfo(id: number): Promise<UserVO> {
  const response = await http.get<UserVO>(`/v1/accounts/${id}`);
  return response;
}

export async function requestResetPassword(id: number): Promise<UserVO> {
  const response = await http.put<UserVO>(`/v1/accounts/${id}/reset:password`);
  return response;
}

export async function requestChangeActivated(id: number, activated: boolean): Promise<UserVO> {
  const response = await http.put<UserVO>(`/v1/accounts/activate:${id}`, { activated });
  return response;
}

export async function requestAssignedGroupList(
  id: number,
  pageNumber: number,
  rowCount: number,
): Promise<Pageable<GroupVO[]>> {
  const response = await http.get<Pageable<GroupVO[]>>(`/v1/accounts/${id}/filter:assigned-feature-groups`, {
    pageNumber,
    rowCount,
  });
  return response;
}

export async function requestUnassignedGroupList(
  id: number,
  pageNumber: number,
  rowCount: number,
): Promise<Pageable<GroupVO[]>> {
  const response = await http.get<Pageable<GroupVO[]>>(`/v1/accounts/${id}/filter:unassigned-feature-groups`, {
    pageNumber,
    rowCount,
  });
  return response;
}

export async function requestPutFeatureGroup(data: FeatureGroupPutRequest): Promise<UserVO> {
  const response = await http.post<UserVO>('/v1/accounts/assign:feature-groups', data);
  return response;
}
