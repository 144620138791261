import React, { useEffect, useState } from 'react';
import { unstable_batchedUpdates } from 'react-dom';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import Pageable from 'models/Pageable';
import { GroupVO } from 'models/vo/GroupVo';
import usePageable from 'utils/hooks/usePageable';
import { requestGroupList } from 'utils/http/api/feature-groups';
import useMounted from 'utils/hooks/useMounted';
import TableHeader, { HeaderTypes } from 'components/stateless/Table/TableHeader';
import TableBorder from 'components/stateless/Table/TableBorder';
import TableBody from 'components/stateless/Table/TableBody';
import Tr from 'components/stateless/Table/Tr';
import Td from 'components/stateless/Table/Td';
import Pagination from 'components/stateless/Pagination/Pagination';
import { HeaderTitle } from 'components/stateless/Title/HeaderTitle';
import TdLink from 'components/stateless/Table/TdLink';
import Button, { ButtonColorEnum, ButtonSizeEnum, ButtonVariantEnum } from 'components/stateless/Button/Button';

const GroupList = () => {
  const { pageable, setPageable } = usePageable();
  const mounted = useMounted();
  const { t } = useTranslation();
  const [groupPage, setGroupPage] = useState<Pageable<GroupVO[]>>();
  const navigate = useNavigate();

  useEffect(() => {
    if (mounted) {
      fetchGroupList(pageable.currentPage, pageable.sizePerPage).then(() => console.log('fetched'));
    }
  }, [mounted]);

  async function fetchGroupList(pageNumber: number, sizePerPage: number) {
    try {
      const groupPageResult = await requestGroupList(pageNumber, sizePerPage);
      unstable_batchedUpdates(() => {
        setGroupPage(groupPageResult);
        setPageable(groupPageResult);
      });
    } catch (e) {
      console.log('error', e);
    }
  }

  const paginate = (pageNumber: number, sizePerPage: number) => {
    fetchGroupList(pageNumber, sizePerPage);
  };

  const tableHeaders: HeaderTypes[] = [
    {
      headerText: t('text:등록일시'),
      colWidths: 100,
    },
    {
      headerText: t('text:역할명'),
      colWidths: 100,
    },
    {
      headerText: '',
      colWidths: 50,
    },
  ];

  const renderResultTable = () => {
    return groupPage?.content.map((item) => {
      return (
        <Tr key={item.featureGroupId}>
          <Td data={item.createDateTime} format={'datetime'} />
          <Td data={item.featureGroupName} />
          <TdLink path={`/admin/group/detail/${item.featureGroupId}`} />
        </Tr>
      );
    });
  };

  return (
    <div className="content-wrap">
      <HeaderTitle title={t('text:역할_그룹_관리')} />
      <div className="content-area">
        <TableBorder>
          <TableHeader header={tableHeaders} />
          <TableBody numOfCol={tableHeaders.length}>{renderResultTable()}</TableBody>
        </TableBorder>
        <Pagination pageable={pageable} paginate={paginate} />
        <Button
          onClick={() => navigate('/admin/group/register')}
          size={ButtonSizeEnum.MD}
          variant={ButtonVariantEnum.OUTLINED}
          color={ButtonColorEnum.PRIMARY}
          className="register-btn"
        >
          {t('text:등록')}
        </Button>
      </div>
    </div>
  );
};

export default GroupList;
