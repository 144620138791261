import React from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { faPowerOff } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { clearLocal, getSignIn } from 'utils/storage/LocalStorage';
import { LOGO } from './constants';
import './Header.scss';

function Header() {
  const navigate = useNavigate();
  const { t } = useTranslation();

  const onClickLogo = (e: any) => {
    e.preventDefault();
    const token = getSignIn()?.accessToken;
    if (token) {
      navigate('/admin/user/user-list');
    } else navigate('/');

    document.body.scrollTo(0, 0);
  };
  const signOut = (e: any) => {
    e.preventDefault();
    if (confirm(t('text:로그아웃 하시겠습니까?'))) {
      clearLocal();
      setTimeout(() => {
        navigate('/');
      }, 0);
    } else {
      return false;
    }
  };

  return (
    <div className="header-bar">
      <a className="logo" onClick={onClickLogo}>
        <img src={`data:image/png;base64,${LOGO}`} />
      </a>
      <h2 className="header-bar__title">{t('text:Supply_Chain_Financing_Platform')}</h2>

      <ul className="header-bar__menu">
        <li>
          <a href="" onClick={signOut}>
            <FontAwesomeIcon icon={faPowerOff} />
          </a>
        </li>
      </ul>
    </div>
  );
}

export default Header;
