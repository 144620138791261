import { useTranslation } from 'react-i18next';
import { leftMenuInfoType } from 'components/stateless/LeftMenu/LeftMenu';
import path from 'path';

export function adminMenuInfo() {
  const { t } = useTranslation(['format']);
  const menuInfo: Array<leftMenuInfoType> = [
    {
      mainMenu: {
        title: t('text:사용자_관리'),
        path: '/admin/user/user-list',
      },
    },
    {
      mainMenu: {
        title: t('text:권한_그룹_관리'),
        path: '/admin/group/group-list',
      },
    },
  ];

  return menuInfo;
}
